import React from 'react';
import getText, { HtmlParser } from 'AppUtils/language';

import GamesSlider from '../subcomponents/GamesSlider';

import './Games.scss';

const GamesComponent = (props) => {
	return (
		<div className="play-component vertical-center">
			<div className="title">
				<h2>
					{props.virtualGames ?
						getText('virtual_games[text_title]')
						:
						props.pregames ?
							getText('pregames[text_title]')
							:
							getText('games[text_title]')
					}
				</h2>
				<p>
					{props.virtualGames ?
						<HtmlParser html="virtual_games[text_subtitle]" />
						:
						props.pregames ?
							<HtmlParser html="pregames[text_subtitle]" />
						:
						<HtmlParser html="games[text_subtitle]" />
					}
				</p>
			</div>
			<GamesSlider
				lang={props.lang}
				games={props.games}
				virtualGames={props.virtualGames}
				pregames={props.pregames}
				userInfoGamesOrPregames={props.userInfoGamesOrPregames}
				handlePlayGame={props.handlePlayGame}
			/>
			<p className="tip">
				{props.virtualGames ?
					getText('virtual_games[text_tip]')
					:
					props.pregames ?
					getText('pregames[text_tip]')
					:
					getText('games[text_tip]')
				}
			</p>
		</div>
	);
};

export default GamesComponent;
