import { apiGet, apiPost, apiPut } from "AppUtils/api";
import { getValue } from 'AppUtils/objects';
import Observable from 'Observable';
import {
	fork,
	put,
	select,
	takeEvery
} from 'redux-saga/effects';

import { authApikey, authUuid } from "../../auth/store/selectors";
import * as AUTH from '../../auth/store/types';
import * as USER from './types';

function* onLoadLoginWithToken(action) {
	const token = getValue(action, 'payload.token', '');
	const uuid = yield select(authUuid);

	const retry = 3;
	let apikey = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost('/user/login-via-token', { token, uuid })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && response.apikey) {
				apikey = response.apikey;

			} else {
				msg = response.error;
			}
		});

	if (apikey) {
		yield put({ type: USER.USER_LOAD_EVENT, payload: { type: 'LOGIN' } });
	}

	yield put({ type: USER.USER_SET_LOGIN_WITH_TOKEN, payload: { apikey, msg, statusCode }});
}

function* onLoadUserEvent(action) {
	// APP_LAUNCH: Application Launch
	// LOGIN: Login attempt
	// ACTVITIY_START: Session Launch
	// ACTIVITY_END: session end
	// SESSION_END (or GAME_END): Session End
	// APP_CLOSE: Application Close
	// UPD_POINTS: Points Update
	// VIEW_SP: View subscription prompt
	// CLICK_SP: Clicked subscription prompt
	const type = getValue(action, 'payload.type', '');
	const metadata = getValue(action, 'payload.metadata', '');
	const uuid = yield select(authUuid);

	const retry = 3;
	let event = '';
	let statusCode = '';

	const response = yield apiPost(`/user/event`, { uuid, type, metadata })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response) {
				event = type;
			} else {
				event = 'error';
			}
		});

	yield put({ type: USER.USER_SET_EVENT, payload: { event, statusCode }});
}

function* onLoadUserInfo(action) {
	const uuid = yield select(authUuid);
	const apikey = getValue(action, 'payload.apikey', '') ? action.payload.apikey : yield select(authApikey);

	const retry = 3;
	let info = '';
	let statusCode = '';
	//let expires = '';
	let eligible = '';
	let msg = '';
	let scheme = '';
	let credits = '';
	let session = null;

	yield put({
		type: AUTH.AUTH_RESET,
		payload: { status: 'guest' },
	});

	//yield put({ type: USER.USER_LOAD_CREDITS });

	const response = yield apiGet(`/user/info`, { uuid }, { apikey })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && response.settings) {
				info = response;
				//expires = getValue(response, 'eligibility.expires');
				eligible = getValue(response, 'eligibility.eligible');
				scheme = getValue(response, 'eligibility.scheme');
				credits = getValue(response, 'eligibility.credits');
				session = response.session;
			} else {
				msg = 'Error';
			}
		});

	if (!msg) {
		//user valid
		if ((scheme == 'subscription' && eligible)
			|| (scheme == 'credits' && credits > 0)) {
			yield put({
				type: AUTH.AUTH_SET,
				payload: { apikey, status: 'valid', statusCode },
			});
		} else {
			if (credits === 0 && !!session && !!session.open) {
				// user started with 1 credit, now has 0 but can continue playing
				yield put({
					type: AUTH.AUTH_SET,
					payload: { apikey, status: 'valid', statusCode },
				});
			} else {
				const path = window.location.pathname;
				if (path !== '/result') {
					yield put({
						type: AUTH.AUTH_SET,
						payload: { apikey, status: 'restricted', statusCode },
					});
				}
			}
			// yield put({
			// 	type: AUTH.AUTH_SET,
			// 	payload: { apikey, status: 'restricted', statusCode },
			// });
		}

		yield put({
			type: USER.USER_SET_INFO,
			payload: { info, statusCode },
		});
	}
}

function* onLoadUpdateUserInfo(action) {
	const settings = getValue(action, 'payload.settings', {});

	const retry = 3;
	let updateInfo = '';
	let statusCode = '';
	let msg = '';

	const response = yield apiPut(`/user/info`, { settings })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				updateInfo = response;
			} else {
				msg = response.error;
			}
		});

	if (updateInfo) {
		yield put({ type: USER.USER_SET_INFO, payload: { info: updateInfo, statusCode }});
	}

	yield put({ type: USER.USER_SET_UPDATE_INFO, payload: { updateInfo: msg ? '' : updateInfo, msg, statusCode }});
}

function* onLoadUserPoints() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let points = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/user/points`, { uuid })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				points = response;
			} else {
				msg = response.error;
			}
		});

	yield put({ type: USER.USER_SET_POINTS, payload: { points, msg, statusCode }});
}

function* onLoadUserCredits() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let credits = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/user/credits`, { uuid })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				credits = response;
			} else {
				msg = response.error;
			}
		});

	yield put({ type: USER.USER_SET_CREDITS, payload: { credits, msg, statusCode }});
}

function* onLoadLeaderBoard() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let leaderBoard = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/user/leaderboard`, { uuid })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				leaderBoard = response;
			} else {
				msg = response.error;
			}
		});

	yield put({ type: USER.USER_SET_LEADER_BOARD, payload: { leaderBoard, msg, statusCode }});
}

function* onLoadUnsubscribe() {
	const uuid = yield select(authUuid);

	const retry = 3;
	let unsubscribe = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost(`/user/unsubscribe`, { uuid })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				unsubscribe = 'done';
			} else {
				unsubscribe = 'error';
			}
		});

	yield put({ type: USER.USER_SET_UNSUBSCRIBE, payload: { unsubscribe, msg, statusCode }});
}

function* userWatchInitialize() {
	yield takeEvery(USER.USER_LOAD_UPDATE_INFO, onLoadUpdateUserInfo);
	yield takeEvery(USER.USER_LOAD_INFO, onLoadUserInfo);
	yield takeEvery(USER.USER_LOAD_POINTS, onLoadUserPoints);
	yield takeEvery(USER.USER_LOAD_CREDITS, onLoadUserCredits);
	yield takeEvery(USER.USER_LOAD_EVENT, onLoadUserEvent);
	yield takeEvery(USER.USER_LOAD_LOGIN_WITH_TOKEN, onLoadLoginWithToken);
	yield takeEvery(USER.USER_LOAD_LEADER_BOARD, onLoadLeaderBoard);
	yield takeEvery(USER.USER_LOAD_UNSUBSCRIBE, onLoadUnsubscribe);
}


export default function* sagas() {
	yield fork(userWatchInitialize);
}
