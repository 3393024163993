import React, {useState} from 'react';
import { useGesture } from 'react-use-gesture';
import { useSprings, animated } from 'react-spring';

import './DragGame.scss';

import iconTimer from '../../main/img/icon-timer.png';
import Timer from 'react-compound-timer';

const DragGameComponent = (props) => {
	const [springs, setSprings] = useSprings(props.answers.length, index => ({ xy: [0, 0] }));
	const [finished, setFinished] = useState(false);

	const calculateIntersect = (rect1, rect2) => {
		let x_overlap = Math.max(0, Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left));
		let y_overlap = Math.max(0, Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top));
		let overlapArea = x_overlap * y_overlap;

		return overlapArea;
	}

	const handleDragStart = (index) => {

	}

	const handleDrag = (index, delta, down) => {
		if(!finished) {
			setSprings(springIndex => {
				if(springIndex === index) {
					if(down) {
						return {
							xy:  delta, immediate: true
						}
					}
				}
			})
		}
	}

	const handleDragEnd = (index, delta, down, direction) => {
		const originalWithoutBound = props.originalRef.current.getBoundingClientRect();
		let originalWithBounding = {
			left: originalWithoutBound.left,
			right: originalWithoutBound.right,
			top: originalWithoutBound.top,
			bottom: originalWithoutBound.bottom,
		}

		const answerWithoutBound = props.answersListRef[index].getBoundingClientRect();
		let answerWithBounding = {
			left: answerWithoutBound.left,
			right: answerWithoutBound.right,
			top: answerWithoutBound.top,
			bottom: answerWithoutBound.bottom,
		}

		const intersectPercentage = calculateIntersect(originalWithBounding, answerWithBounding);

		setSprings(springIndex => {
			if(springIndex === index) {
				if(intersectPercentage === 0) {
					return {
						xy: [0, 0]
					}
				} else {
					setFinished(true)
					props.timer.pause();
					props.setChoice(props.answers[index].id)
					setTimeout(() => {
						props.finishGame();
					}, 1000)
				}
			}
		})
	}

	const bindDrag = useGesture({
		onDragStart: () => {
			console.log('drag')
			handleDragStart();
		},
		onDrag: ({ args: [originalIndex], down, delta, velocity }) => {
			console.log('dragging')
			console.log(originalIndex, 'originalIndex')
			handleDrag(originalIndex, delta, down);
		},
		onDragEnd: ({ args: [originalIndex], down, delta, velocity }) => {
			handleDragEnd(originalIndex, delta, down);
		}
	})

	const renderAnswers = () => {
		return springs.map(({xy}, i) => {
			return (
				<animated.div
					key={i}
					className={`answer answer-${i}`}
					style={{ transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`) }}
					{...bindDrag(i)}
					ref={answersListRef => props.answersListRef[i] = answersListRef}
				>
					<img
						src={props.answers[i].image}
						alt=""
					/>
				</animated.div>
			)
		})
	}

	return (
		<div className="drag-game">
			<div className="title">
				<h2>{props.playGetGame.name[props.lang]}</h2>
				<p>{props.playGetGame.subtitle[props.lang]}</p>
			</div>
			<div className="game-timer">
				<img src={iconTimer} alt=""/> <div className="time-left"><Timer.Minutes formatValue={value => ("0" + value).slice(-2)} />:<Timer.Seconds formatValue={value => ("0" + value).slice(-2)} /></div>
			</div>

			<div className="game-wrapper">
				<div className="original"><img src={props.playGetGame.data && props.playGetGame.data.images ? props.playGetGame.data.images.original.image : ''} alt="" ref={props.originalRef} /></div>
				<div className="answers-list">
					{renderAnswers()}
				</div>
			</div>
		</div>
	);
};

export default DragGameComponent;
