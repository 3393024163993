import React from 'react';
import Timer from 'react-compound-timer';

import './Differences.scss';
import clsx from 'clsx';

import iconTimer from '../../main/img/icon-timer.png';

const DifferencesComponent = (props) => {
	return (
		<div className="differences-game">
			<div className="title">
				<h2>{props.playGetGame.name[props.lang]}</h2>
				<p>{props.playGetGame.subtitle[props.lang]}</p>
			</div>
			<div className="game-timer">
				<img src={iconTimer} alt=""/> <div className="time-left"><Timer.Minutes formatValue={value => ("0" + value).slice(-2)} />:<Timer.Seconds formatValue={value => ("0" + value).slice(-2)} /></div>
			</div>

			<div className="game-wrapper">
				<div className="original">
					<img src={props.playGetGame.data.originalImage.image} alt=""/>
					<div
						className={clsx('difference', 'difference-1', props.selectedDifferences.includes(1) && 'selected')}
						style={{top: `${props.playGetGame.data.differences[0].y}%`, left: `${props.playGetGame.data.differences[0].x}%`}}
						onClick={() => props.handleClickDifference(1)}
					/>
					<div
						className={clsx('difference', 'difference-2', props.selectedDifferences.includes(2) && 'selected')}
						style={{top: `${props.playGetGame.data.differences[1].y}%`, left: `${props.playGetGame.data.differences[1].x}%`}}
						onClick={() => props.handleClickDifference(2)}
					/>
					<div
						className={clsx('difference', 'difference-3', props.selectedDifferences.includes(3) && 'selected')}
						style={{top: `${props.playGetGame.data.differences[2].y}%`, left: `${props.playGetGame.data.differences[2].x}%`}}
						onClick={() => props.handleClickDifference(3)}
					/>
				</div>
				<div className="different">
					<img src={props.playGetGame.data.differentImage.image} alt=""/>
					<div
						className={clsx('difference', 'difference-1', props.selectedDifferences.includes(1) && 'selected')}
						style={{top: `${props.playGetGame.data.differences[0].y}%`, left: `${props.playGetGame.data.differences[0].x}%`}}
						onClick={() => props.handleClickDifference(1)}
					/>
					<div
						className={clsx('difference', 'difference-2', props.selectedDifferences.includes(2) && 'selected')}
						style={{top: `${props.playGetGame.data.differences[1].y}%`, left: `${props.playGetGame.data.differences[1].x}%`}}
						onClick={() => props.handleClickDifference(2)}
					/>
					<div
						className={clsx('difference', 'difference-3', props.selectedDifferences.includes(3) && 'selected')}
						style={{top: `${props.playGetGame.data.differences[2].y}%`, left: `${props.playGetGame.data.differences[2].x}%`}}
						onClick={() => props.handleClickDifference(3)}
					/>
				</div>
			</div>
		</div>
	);
};

export default DifferencesComponent;
