import React from 'react';

import SpinTheWheel from '../subcomponents/spin-the-wheel/SpinTheWheelContainer';
import ScratchCards from '../subcomponents/scratch-cards/main/ScratchCardsContainer';
import Knock from '../subcomponents/knock/KnockContainer';

const PregameDetailsComponent = (props) => {
	return (
		<div className="game-details-component vertical-center">
			{
				props.gameId === 1 ?
					<SpinTheWheel playGetPregame={props.playGetPregame} lang={props.lang} uiRedirect={props.uiRedirect} prizeId={props.prizeId} />
					:
					props.gameId === 2 ?
						<ScratchCards playGetPregame={props.playGetPregame} lang={props.lang} uiRedirect={props.uiRedirect} prizeId={props.prizeId}  />
						:
						props.gameId === 3 ?
							<Knock playGetPregame={props.playGetPregame} lang={props.lang} uiRedirect={props.uiRedirect} prizeId={props.prizeId} />
									:
									null
			}
		</div>
	);
};

export default PregameDetailsComponent;
