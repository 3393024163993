import React, { Component } from 'react';

import { connect } from 'react-redux';
import { uiRedirect } from '../../../store/actions';
import { appGetInterfaceLang } from '../../../store/selectors';
import { userLoadEvent, userLoadInfo } from '../../user/store/actions';
import { playLoadFinishGame } from '../store/actions';
import { playFinishGame, playGetGame } from '../store/selectors';
import Result from './ResultComponent';

import sorry from '../../../resources/sounds/sorry.mp3';
import tada from '../../../resources/sounds/tada.mp3';

const stateToProps = (state, props) => {
	const gameId = props.match.params.id;
	const playGame = playGetGame(state, gameId);

	return (state) => ({
		playGetGame: playGame(state),
		playFinishGame: playFinishGame(state),
		lang: appGetInterfaceLang(state),
	});
};

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	playLoadFinishGame: (payload) => dispatch(playLoadFinishGame(payload)),
	userLoadEvent: (data) => dispatch(userLoadEvent(data)),
	userLoadInfo: (payload) => dispatch(userLoadInfo(payload)),
});

@connect(stateToProps, actionsToProps)
class ResultContainer extends Component {

	audioCongrats = new Audio(tada);
	audioSorry = new Audio(sorry);

	state = {
		playFinishGame: null
	}

	handleCountdown = () => {
		this.props.userLoadInfo();
	}

	componentDidMount() {
		this.audioSorry.pause();
		this.audioCongrats.pause();
		const { location } = this.props;
		if (!this.props.location.state || this.props.location.state === undefined || !this.props.location.state.gamePlayId) {
			this.props.uiRedirect({
				pathname: '/'
			})
		} else {
			this.props.playLoadFinishGame({gamePlayId: location.state.gamePlayId, answers: JSON.stringify(location.state.answers), time: location.state.time})
			this.props.userLoadEvent({ type: 'ACTIVITY_END', metadata: `{"activity_session_id": ${Math.floor(Math.random() * 1000)}, "activity_type": 2, "activity_subtype": ${this.props.location.state.gamePlayId}}`})
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.playFinishGame !== this.props.playFinishGame) {
			this.setState({
				playFinishGame: this.props.playFinishGame
			})

			if(this.props.playFinishGame.result) {
				this.audioCongrats.play();
			} else if(this.props.playFinishGame && !this.props.playFinishGame.result) {
				this.audioSorry.play();
			}

			if(this.props.playFinishGame === '') {
				this.props.uiRedirect({
					pathname: '/'
				})
			}
		}
	}

	render() {
		if(this.state.playLoadFinishGame === null) {
			return null;
		}

		return (
			<Result
				playFinishGame={this.state.playFinishGame}
				session={this.props.userInfo.session}
				scheme={this.props.userInfo.eligibility.scheme}
				totalPoints={this.props.userInfo.points}
				handleCountdown={this.handleCountdown}
			/>
		);
	}
}

export default ResultContainer;
