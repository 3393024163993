const PLAY_LOAD_MSG = 'PLAY_LOAD_MSG';
const PLAY_SET_MSG = 'PLAY_SET_MSG';

const PLAY_LOAD_GAMES = 'PLAY_LOAD_GAMES';
const PLAY_SET_GAMES = 'PLAY_SET_GAMES';

const PLAY_LOAD_PREGAMES = 'PLAY_LOAD_PREGAMES';
const PLAY_SET_PREGAMES = 'PLAY_SET_PREGAMES';

const PLAY_LOAD_EXTERNAL_GAMES = 'PLAY_LOAD_EXTERNAL_GAMES';
const PLAY_SET_EXTERNAL_GAMES = 'PLAY_SET_EXTERNAL_GAMES';

const PLAY_LOAD_START_GAME = 'PLAY_LOAD_START_GAME';
const PLAY_SET_START_GAME = 'PLAY_SET_START_GAME';

const PLAY_LOAD_FINISH_GAME = 'PLAY_LOAD_FINISH_GAME';
const PLAY_SET_FINISH_GAME = 'PLAY_SET_FINISH_GAME';

const PLAY_LOAD_PICK_PREGAME = 'PLAY_LOAD_PICK_PREGAME';
const PLAY_SET_PICK_PREGAME = 'PLAY_SET_PICK_PREGAME';

const PLAY_LOAD_PLAY_PREGAME = 'PLAY_LOAD_PLAY_PREGAME';
const PLAY_SET_PLAY_PREGAME = 'PLAY_SET_PLAY_PREGAME';

export {
	PLAY_LOAD_MSG,
	PLAY_SET_MSG,
	PLAY_LOAD_GAMES,
	PLAY_SET_GAMES,
	PLAY_LOAD_EXTERNAL_GAMES,
    PLAY_SET_EXTERNAL_GAMES,
	PLAY_LOAD_START_GAME,
	PLAY_SET_START_GAME,
	PLAY_LOAD_FINISH_GAME,
	PLAY_SET_FINISH_GAME,
	PLAY_LOAD_PREGAMES,
	PLAY_SET_PREGAMES,
	PLAY_LOAD_PICK_PREGAME,
	PLAY_SET_PICK_PREGAME,
	PLAY_LOAD_PLAY_PREGAME,
	PLAY_SET_PLAY_PREGAME,
};
