import React, {Component} from 'react';
import Timer from 'react-compound-timer'

import DifferencesComponent from './DifferencesComponent';

import '../Timer.scss';

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
	<Timer {...timerProps}>
		{timerRenderProps =>
			<WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
	</Timer>
);

class DifferencesContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedDifferences: []
		}

		props.timer.setCheckpoints([
			{
				time: 0,
				callback: () => {
					this.finishGame();
				},
			},
		]);

		props.timer.setTime(props.playGetGame.data.maxSeconds * 1000 + 500);
		props.timer.start();
	}

	finishGame = () => {
		this.props.uiRedirect({
			pathname: '/result',
			state: {
				gamePlayId: parseInt(this.props.playStartGame.id),
				time: parseInt(this.props.timer.getTime()/1000) === 0 ? -1 : parseInt(this.props.timer.getTime()/1000),
			}
		})
	}

	handleClickDifference = (index) => {
		let copySelectedDifferences = [...this.state.selectedDifferences];
		if(!copySelectedDifferences.includes(index)) {
			copySelectedDifferences.push(index);

			this.setState({
				selectedDifferences: copySelectedDifferences
			}, () => {
				if(copySelectedDifferences.length === 3) {
					setTimeout(() => {
						this.finishGame();
					}, 1000)
				}
			})
		}
	}

	render() {
		return (
			<DifferencesComponent
				timer={this.props.timer}
				lang={this.props.lang}
				playGetGame={this.props.playGetGame}
				selectedDifferences={this.state.selectedDifferences}
				handleClickDifference={this.handleClickDifference}
			/>
		);
	}
}

const TimerHOC = withTimer({
	direction: 'backward',
	initialTime: 60500,
	startImmediately: false,
})(DifferencesContainer);

<TimerHOC />

export default TimerHOC;
