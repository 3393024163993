import React from 'react';

import ScratchCard from '../subcomponents/ScratchCard';

import './ScratchCards.scss';

const ScratchCardsComponent = (props) => {

	const getSettings = (index) => {
		return {
			width: 104,
			height: 130,
			image: props.playGetPregame.data.defaultImage,
			finishPercent: 70,
			onComplete: () => props.handleScratchComplete(),
			onMouseDown: () => props.handleScratch(index),
		}
	};

	const renderCard = () => {
		return props.playGetPregame.prizes.map((prize, index) => (
			<li key={index}>
				<div className="scratch-card-wrapper" style={{ backgroundColor: props.prize.backgroundColor }}>
					<ScratchCard {...getSettings(index)} disabled={index !== props.startScratchIndex && props.startScratchIndex ? true : false}>
						<div className="prize">
							<p>{props.prize.texts[props.lang][0]}</p>
							<span>{props.prize.texts[props.lang][1]}</span>
						</div>
					</ScratchCard>
				</div>
			</li>
		))
	}

	return (
		<div className="scratch-pregame">
			<div className="title">
				<h2>{props.playGetPregame.name[props.lang]}</h2>
				<p>{props.playGetPregame.subtitle[props.lang]}</p>
			</div>

			<div className="game-wrapper">
				<ul className="cards-list">
					{renderCard()}
				</ul>
			</div>
		</div>
	);
};

export default ScratchCardsComponent;
