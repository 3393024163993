import React from 'react';
import { Link } from 'react-router-dom';
import getText from '../../../utils/language';

import Ribbon from '../../../common/ribbon/RibbonComponent';
import Button from '../../../common/button/ButtonComponent';

import './Confirm.scss';

const ConfirmComponent = (props) => {
	return (
		<div className="confirm-component vertical-center">
			<div className="confirm-info">
				<Ribbon
					width={280}
					sizeBig={true}
					fill={props.prize.backgroundColor}
				/>
				<div className="info-content">
					<span className="confirm-title">You're now playing for</span>
					<strong className="confirm-points">{props.prize.texts[props.lang][0]}</strong>
					<span className="confirm-points-text">{props.prize.texts[props.lang][1]}</span>
				</div>
			</div>
			<Link to="/play">
				<Button
					fullWidth={true}
					boxShadow={true}
				>
					I like it! Play now!
				</Button>
			</Link>
		</div>
	);
};

export default ConfirmComponent;
