import React from 'react';

import './Knock.scss';

const KnockComponent = (props) => {

	const renderCard = () => {
		return props.playGetPregame.prizes.map((card, index) => (
			<li key={index}>
				<div className="card-container">
					<div
						className={"card" + (props.isFlipped === index ? " flipped" : "")}
						onClick={() => props.handleKnock(index)}
					>
						<div className="side front" style={{backgroundImage: `url(${props.playGetPregame.data.doors[index]})`}}></div>
						{/*<img src={imageURL} className="side front" />*/}
						<div className="side back" style={{ backgroundColor: props.prize.backgroundColor }}>
							<div className="prize">
								<p>{props.prize.texts[props.lang][0]}</p>
								<span>{props.prize.texts[props.lang][1]}</span>
							</div>
						</div>
					</div>
				</div>
			</li>
		))
	}

	return (
		<div className="knock-pregame">
			<div className="title">
				<h2>{props.playGetPregame.name[props.lang]}</h2>
				<p>{props.playGetPregame.subtitle[props.lang]}</p>
			</div>

			<div className="game-wrapper">
				<ul className="cards-list">
					{renderCard()}
				</ul>
			</div>
		</div>
	);
};

export default KnockComponent;
