import React from 'react';

import './SpinTheWheel.scss';
import clsx from 'clsx';

const SpinTheWheelComponent = (props) => {

	const wheelVars = {
		'--nb-item': props.playGetPregame.prizes.length,
		'--selected-item': props.selectedItem,
	};
	const spinning = props.selectedItem !== null ? 'spinning' : '';

	const renderItems = () => {
		return props.playGetPregame.prizes.map((prize, index) => {
			return (
				<div
					className="wheel-item"
					key={index}
					style={{ '--item-nb': index }}>
					<p>
						<strong>{prize.texts[props.lang][0]}</strong>
						<span>{prize.texts[props.lang][1]}</span>
						{prize.texts[props.lang][2]}
					</p>
				</div>
			)
		})
	}

	return (
		<div className="spin-pregame" style={{ '--spinning-duration': `${props.spinDuration}s` }}>
			<div className="title">
				<h2>{props.playGetPregame.name[props.lang]}</h2>
				<p>{props.playGetPregame.subtitle[props.lang]}</p>
			</div>
			<div className="game-wrapper">
				<div className="wheel-container">
					<div className={clsx('spin-text', spinning && 'hide')}><p>Tap to spin</p></div>
					<div className={`wheel ${spinning}`} style={wheelVars} onClick={props.selectItem}>
						{renderItems()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SpinTheWheelComponent;
