import { createAction } from 'redux-actions';

import * as PLAY from './types';

const playLoadMsg = createAction(PLAY.PLAY_LOAD_MSG);
const playSetMsg = createAction(PLAY.PLAY_LOAD_MSG);

const playLoadGames = createAction(PLAY.PLAY_LOAD_GAMES, payload => payload);
const playLoadPregames = createAction(PLAY.PLAY_LOAD_PREGAMES, payload => payload);
const playLoadPickPregame = createAction(PLAY.PLAY_LOAD_PICK_PREGAME, payload => payload);
const playLoadPlayPregame = createAction(PLAY.PLAY_LOAD_PLAY_PREGAME, payload => payload);
const playLoadExternalGames = createAction(PLAY.PLAY_LOAD_EXTERNAL_GAMES, payload => payload);
const playLoadStartGame = createAction(PLAY.PLAY_LOAD_START_GAME, payload => payload);
const playLoadFinishGame = createAction(PLAY.PLAY_LOAD_FINISH_GAME, payload => payload);

export {
	playLoadMsg,
	playSetMsg,
	playLoadGames,
	playLoadPregames,
	playLoadPickPregame,
	playLoadPlayPregame,
	playLoadExternalGames,
	playLoadStartGame,
	playLoadFinishGame,
}
