import React, { Component } from "react";
import { connect } from "react-redux";
import { getValue } from "../../../../utils/objects";

import Games from "./GamesComponent";

import { userEvent } from "../../../user/store/selectors";

import {
	playGetExternalGames,
	playGetGames,
	playGetPregames,
	playStartGame,
} from "../../store/selectors";

import { uiRedirect } from "../../../../store/actions";
import { appGetInterfaceLang } from "../../../../store/selectors";
import { userLoadEvent, userLoadInfo } from "../../../user/store/actions";
import {
	playLoadExternalGames,
	playLoadGames,
	playLoadPregames,
	playLoadStartGame,
} from "../../store/actions";

const stateToProps = (state) => ({
	playGetGames: playGetGames(state),
	playGetPregames: playGetPregames(state),
	playStartGame: playStartGame(state),
	playGetExternalGames: playGetExternalGames(state),
	userEvent: userEvent(state),
	lang: appGetInterfaceLang(state),
});

const actionsToProps = (dispatch) => ({
	playLoadPregames: (payload) => dispatch(playLoadPregames(payload)),
	playLoadGames: (payload) => dispatch(playLoadGames(payload)),
	playLoadExternalGames: (payload) =>
		dispatch(playLoadExternalGames(payload)),
	playLoadStartGame: (payload) => dispatch(playLoadStartGame(payload)),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	userLoadEvent: (data) => dispatch(userLoadEvent(data)),
	userLoadInfo: (payload) => dispatch(userLoadInfo(payload)),
});

@connect(stateToProps, actionsToProps)
class GamesContainer extends Component {
	constructor(props) {
		super(props);

		console.log("THIS PROPS", this.props);

		if (!getValue(props.userInfo, "session.pregame")) {
			if (
				!props.userInfo?.session?.open &&
				props.userInfo?.eligibility?.credits === 0
			) {
				this.props.userLoadInfo();
			} else {
				this.props.uiRedirect({
					pathname: "/play-pregame",
				});
			}
		} else if (
			getValue(props.userInfo, "session.pregame.played") &&
			props.location.pathname === "/play-pregame"
		) {
			this.props.uiRedirect({
				pathname: "/",
			});
		} else if (
			getValue(props.userInfo, "session.pregame") &&
			!getValue(props.userInfo, "session.pregame.played")
		) {
			this.props.uiRedirect({
				pathname: `/play-pregame/${getValue(
					props.userInfo,
					"session.pregame.id"
				)}`,
			});
		}

		this.state = {
			userInfoGamesOrPregames: {},
			pregames: null,
		};
	}

	handlePlayGame = (e, id, gameUrl) => {
		e.preventDefault();
		if (this.state.pregames) {
			this.props.uiRedirect({
				pathname: `/play-pregame/${id}`,
				state: {
					runGame: true,
				},
			});
		} else if (this.state.virtualGames) {
			this.setState(
				{
					redirectTo: gameUrl,
				},
				() =>
					this.props.userLoadEvent({
						type: "ACTIVITY_START",
						metadata: `{"activity_session_id": ${Math.floor(
							Math.random() * 1000
						)}, "activity_type": 3, "activity_subtype": ${id}}`,
					})
			);
		} else {
			if (
				this.props.userInfo.games[id] === "playable" ||
				this.props.userInfo.games[id] === "playing"
			) {
				this.props.uiRedirect({
					pathname: `/play/${id}`,
					state: {
						runGame: true,
					},
				});
			}
		}
	};

	componentDidMount() {
		const path = this.props.location.pathname;

		if (path === "/play") {
			if (this.props.userInfo.games) {
				this.setState({ pregames: false });
				this.props.playLoadGames();
			}
		} else if (path === "/play-virtual") {
			this.setState({
				pregames: false,
				virtualGames: true,
			});
			if (this.props.userInfo.games) {
				this.props.playLoadExternalGames();
			}
		} else if (path === "/play-pregame") {
			this.setState({
				pregames: true,
			});
			this.props.playLoadPregames();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const path = this.props.location.pathname;

		console.log("COMPONENT UPDATES", this.props);
		if (
			prevProps.playGetGames !== this.props.playGetGames &&
			this.props.playGetGames &&
			path === "/play"
		) {
			this.setState({
				playGetGames: this.props.playGetGames,
			});
		}

		if (
			prevProps.playGetExternalGames !==
				this.props.playGetExternalGames &&
			this.props.playGetExternalGames
		) {
			this.setState({
				playGetGames: this.props.playGetExternalGames,
			});
		}

		if (
			prevProps.playGetPregames !== this.props.playGetPregames &&
			this.props.playGetPregames
		) {
			this.setState({
				playGetGames: this.props.playGetPregames,
				userInfoGamesOrPregames: this.props.playGetPregames,
			});
		}

		if (prevState.pregames !== this.state.pregames) {
			if (this.state.pregames === true) {
				this.setState({
					userInfoGamesOrPregames: this.props.userInfo.pregames,
				});
			} else if (this.state.pregames === false) {
				this.setState({
					userInfoGamesOrPregames: this.props.userInfo.games,
				});
			}
		}

		if (
			prevProps.userEvent !== this.props.userEvent &&
			this.props.userEvent === "ACTIVITY_START"
		) {
			window.location.href = this.state.redirectTo;
		}
	}

	render() {
		if (!this.state.playGetGames || !this.state.userInfoGamesOrPregames) {
			return <></>;
		}

		return (
			<Games
				lang={this.props.lang}
				pregames={this.state.pregames}
				virtualGames={this.state.virtualGames}
				games={this.state.playGetGames}
				userInfoGamesOrPregames={this.state.userInfoGamesOrPregames}
				userInfo={this.props.userInfo}
				handlePlayGame={this.handlePlayGame}
			/>
		);
	}
}

export default GamesContainer;
