import getText from 'AppUtils/language';
import React from 'react';

import './Credits.scss';


const CreditsComponent = (props) => {
	return (
		<div className="credits-component vertical-center">
			<h2>{getText('homepage[text_not_elligible_1]')} <br/>
				{getText('homepage[text_not_elligible_2]')}</h2>
			{/* <div className="credits-types">
				{props.creditPackages.length > 0 && props.creditPackages.map((credit) => {
					return (<Link key={credit.id} className="credit-button" onClick={(e) => props.onBuy(e, credit.id)} to="#">
						<div className="description">
							<p>
								<i>{getText('playmore[text_buy]')} </i><img src={iconCoin} alt="" /><span>{credit.credits}</span>
							</p>
							<p className="credits-no">{credit.name[props.lang]}</p>
						</div>
						<div className="price">
							<span>{credit.cost[props.lang]}</span>
							<img src={priceTag} alt="" />
						</div>
					</Link>);
				})}
			</div> */}
		</div>
	);
};

export default CreditsComponent;
