import getText from "AppUtils/language";
import React, { Component } from "react";
import { connect } from "react-redux";

import { appGetConfig } from "AppStore/selectors";
import { userInfo } from "../../user/store/selectors";

const stateToProps = (state) => ({
	appGetConfig: appGetConfig(state),
	userInfo: userInfo(state),
});

const actionsToProps = (dispatch) => ({
	uiToggleModal: (target, options, content = {}) =>
		dispatch(uiToggleModal(target, options, content)),
});

@connect(stateToProps, actionsToProps)
class UnsubscribeContainer extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="credits-component vertical-center">
				<h2>{getText("unsubscribe[text_unsubscribe]")}</h2>
			</div>
		);
	}
}

export default UnsubscribeContainer;
