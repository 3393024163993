import React, {Component} from 'react';
import {connect} from 'react-redux';

import ScratchCards from './ScratchCardsComponent';

import {playGetPlayPregame} from '../../../../store/selectors';
import {uiRedirect} from '../../../../../../store/actions';
import {playLoadPlayPregame} from '../../../../store/actions';

const stateToProps = state => ({
	playGetPlayPregame: playGetPlayPregame(state)
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	playLoadPlayPregame: (payload) => dispatch(playLoadPlayPregame(payload)),
});

@connect(stateToProps, actionsToProps)
class ScratchCardsContainer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			playGetPregame: props.playGetPregame,
			prizeId: props.prizeId,
			startScratchIndex: false
		};

		if(!props.playGetPregame) {
			props.uiRedirect({
				pathname: '/'
			})
		}
	}

	getPrizeIndexWithId = () => {
		let prize = this.state.playGetPregame.prizes.filter((prize, index) => prize.id === this.state.prizeId);
		return this.state.playGetPregame.prizes.indexOf(prize[0]);
	}

	handleScratch = (index) => {
		this.setState({
			startScratchIndex: index
		})
	}

	handleScratchComplete = () => {
		setTimeout(() => {
			this.props.playLoadPlayPregame({id: this.state.playGetPregame.id});
		}, 1000)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let prize = this.state.playGetPregame.prizes.filter((prize, index) => prize.id === this.state.prizeId);

		if(prevProps.playGetPlayPregame !== this.props.playGetPlayPregame && this.props.playGetPlayPregame) {
			this.props.uiRedirect({
				pathname: '/confirm',
				state: {
					prize: prize[0]
				}
			})
		}
	}

	render() {
		let prize = this.state.playGetPregame.prizes.filter((prize, index) => prize.id === this.state.prizeId)[0];

		return (
			<ScratchCards
				lang={this.props.lang}
				playGetPregame={this.state.playGetPregame}
				prize={prize}
				handleScratch={this.handleScratch}
				startScratchIndex={this.state.startScratchIndex}
				handleScratchComplete={this.handleScratchComplete}
			/>
		);
	}
}

export default ScratchCardsContainer;
