import React, {Component} from 'react';
import {connect} from 'react-redux';

import Confirm from './ConfirmComponent';

import {appGetInterfaceLang} from '../../../store/selectors';

const stateToProps = state => ({
	lang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({

});

@connect(stateToProps, actionsToProps)
class ConfirmContainer extends Component {

	state = {
		prize: this.props.location.state.prize
	}

	render() {
		return (
			<Confirm
				lang={this.props.lang}
				prize={this.state.prize}
			/>
		);
	}
}

export default ConfirmContainer;
