import getText, { HtmlParser } from "AppUtils/language";
import clsx from "clsx";
import React from "react";
import Countdown from "react-countdown";

import Button from "../../../common/button/ButtonComponent";

import { Link } from "react-router-dom";
import "./Results.scss";

const ResultComponent = (props) => {
	return (
		<div className="result-component vertical-center">
			{props.playFinishGame && (
				<>
					<div className="title">
						<h2>
							{props.playFinishGame.result
								? getText("congratulations[text_title]")
								: getText("sorry[text_title]")}
						</h2>
						{props.playFinishGame.result ? (
							<p>
								{getText(
									"congratulations[text_subtitle]"
								).replace(
									"numberPoints",
									props.playFinishGame.points
								)}
							</p>
						) : props.playFinishGame.result ? null : (
							<p>
								<HtmlParser html="sorry[text_subtitle]" />
							</p>
						)}
					</div>
					<div className="total-points">
						<span className="gained-points">
							+
							{props.playFinishGame
								? props.playFinishGame.points
								: "0"}
						</span>
						<h3 className="points">{props.totalPoints}</h3>
						<p>{getText("congratulations[text_total_points]")}</p>
					</div>
					<Link
						to="/play"
						className={clsx({
							"btn-again": true,
							inactive: !props.session.open,
						})}
						// onClick={(e) => !props.session.open ? e.preventDefault() : noop}
					>
						<Button fullWidth={true} boxShadow={true}>
							{props.session.open ? (
								getText(
									"congratulations[button_play_next_game]"
								)
							) : (
								<>
									{props.scheme === "credits" ? (
										<>
											{getText(
												"congratulations[button_play_again_for]"
											)}
										</>
									) : (
										<>
											{getText(
												"congratulations[button_play_again_in]"
											)}
											<span className="countdown">
												<Countdown
													date={
														props.session
															? props.session
																	.playAgain
															: 0
													}
													onComplete={() =>
														props.handleCountdown()
													}
													daysInHours={true}
												/>
											</span>
										</>
									)}
								</>
							)}
						</Button>
					</Link>
				</>
			)}

			{/*<AvatarProgress />*/}
		</div>
	);
};

export default ResultComponent;
