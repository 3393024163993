import { createSelector } from 'reselect';

const playMsg = state => state.play.msg;
const playStatusCode = state => state.play.statusCode;
const playGetGames = state => state.play.games;
const playGetPregames = state => state.play.pregames;
const playGetPickPregame = state => state.play.pickPregame;
const playGetPlayPregame = state => state.play.playPregame;
const playGetExternalGames = state => state.play.externalGames;
const playGetPregame = (state, id) => createSelector(
	[playGetPregames], (games) => {
		return games && id && games.find(game => game.id == id);
	},
);
const playGetGame = (state, id) => createSelector(
	[playGetGames], (games) => {
		return games && id && games.find(game => game.id == id);
	},
);
const playGetExternalGame = (state, id) => createSelector(
	[playGetExternalGames], (games) => {
		return games && id && games.find(game => game.id == id);
	},
);
const playStartGame = state => state.play.start;
const playFinishGame = state => state.play.finish;

export {
	playMsg,
	playStatusCode,
	playGetGames,
	playGetPregame,
	playGetPregames,
	playGetPickPregame,
	playGetPlayPregame,
	playGetExternalGames,
	playGetGame,
	playGetExternalGame,
	playStartGame,
	playFinishGame,
};
