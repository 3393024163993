import React from 'react';
import {Link} from 'react-router-dom';
import getText from 'AppUtils/language';

import Button from '../../../../../../common/button/ButtonComponent';

import './Text.scss';

const TextComponent = (props) => {
	return (
		<div className="text-component">
			<div className="inputs-wrapper">
				<p>{getText('register[text_register]')}</p>
			</div>

			<Link to="/signin">
				<Button
					fullWidth={true}
					boxShadow={true}
				>
					{getText('login[button_login]')}
				</Button>
			</Link>
		</div>
	);
};

export default TextComponent;
