import React from "react";
import { Link } from "react-router-dom";

import Menu from "../../menu/MenuContainer";
import PlayerStats from '../subcomponents/PlayerStatsComponent';

import "./Header.scss";

import iconNav from "./img/icon-nav.png";
import iconBack from "./img/icon-arrow-back.png";
import iconClose from "./img/icon-close.png";
import logo from './img/bmp-logo.png';

const HeaderComponent = (props) => {
	return (
		<header>
			<Menu menuIsOpen={props.menuIsOpen} toggleMenu={props.toggleMenu} authIsAuthenticated={props.authIsAuthenticated} authStatus={props.authStatus} authLogout={props.authLogout} />

			<div className="nav-control">
				{props.location === "/faq" ||
				 props.location === "/tos" ||
				 props.location === "/profile" ? (

					<div className="btn-nav">
						<Link to="/">
							<img src={iconClose} alt="Go back" />
						</Link>
					</div>

				) : (

					<div className="btn-nav">
						<img
							src={props.menuIsOpen ? iconBack : iconNav}
							alt="Open nav"
							onClick={() => props.toggleMenu()}
						/>
					</div>

				)}
				<img className="logo-header" src={logo} alt=""/>
			</div>

			{props.authIsAuthenticated && props.userInfo && props.location !== '/about' ?
				<PlayerStats
					userInfo={props.userInfo}
					userCredits={props.userCredits}
				/>
			: null
			}
		</header>
	);
};

export default HeaderComponent;
