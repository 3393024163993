import React, {Component} from 'react';
import { connect } from 'react-redux';
import Observable from 'Observable';
import getText from 'AppUtils/language';

import Credits from './CreditsComponent';
import { appGetConfig, appGetInterfaceLang } from "../../store/selectors";
import { getValue } from 'AppUtils/objects';
import { apiPost } from 'AppUtils/api';
import { uiToggleModal, uiRedirect } from "../../store/actions";

const stateToProps = state => ({
	appGetConfig: appGetConfig(state),
	lang: appGetInterfaceLang(state),
});

const actionsToProps = dispatch => ({
	uiToggleModal: (target, options, content = {}) => dispatch(uiToggleModal(target, options, content)),
	uiRedirect: (url) => dispatch(uiRedirect(url)),
});

@connect(stateToProps, actionsToProps)
class CreditsContainer extends Component {

	openModal = (title, message, button, buttonText, callback) => {
		this.props.uiToggleModal({}, { toggle: true }, { title, message, button, buttonText, callback  } );
	};

	onBuy = (e, packageId) => {
		e.preventDefault();


		apiPost('/user/credits/buy', { packageId })
			.catch((e) => {
				this.openModal(getText('eroare'), getText('recover[error_upstream_3]'));

				return Observable.empty();
			})
			.mergeMap(res => {
				let resp = Promise.resolve({ error: true });
				if (res.ok) {
					resp = res.json();
				}

				return Promise.resolve({ resp, statusCode: res.status });
			})
			.subscribe((result) => {
				const { resp, statusCode } = result;

				resp.then(response => {
					if (response && statusCode == 200) {
						this.openModal(getText('Thank you! Please, wait...'), getText('Please wait for your confirmation over SMS, your MNO is processing the charge. You will soon be eligible to enjoy more games.'));
					} else {
						let errorMsg = '';
						switch (statusCode) {
							case 400:
								errorMsg = getText('recover[error_upstream_400]');
								break;
							case 401:
								errorMsg = getText('recover[error_upstream_401]');
								break;
							case 403:
								errorMsg = getText('recover[error_upstream_403]');
								break;
							case 404:
								errorMsg = getText('recover[error_upstream_404]');
								break;
							case 500:
								errorMsg = getText('recover[error_upstream_500]');
								break;
							case 502:
								errorMsg = getText('recover[error_upstream_502]');
								break;
							case 503:
								errorMsg = getText('recover[error_upstream_503]');
								break;
							default:
								errorMsg = getText('recover[error_upstream_401]');
						}
						this.openModal(getText('global[text_error]'), errorMsg);
					}
				});
			});
	}

	render() {
		const creditPackages = getValue(this.props, 'appGetConfig.creditPackages', []);

		return (
			<Credits
				creditPackages={creditPackages}
				lang={this.props.lang}
				onBuy={this.onBuy}
			/>
		);
	}
}

export default CreditsContainer;
