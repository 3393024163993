import React from 'react';
import {
	Switch,
	Route,
} from "react-router-dom";

import CheckAuth from './components/auth/CheckAuth';
import Auth from './components/auth/main/AuthContainer';
import About from './components/about/AboutContainer';
import Leaderboard from './components/leaderboard/LeaderboardContainer';
import Profile from './components/profile/ProfileContainer';
import Games from './components/play/games/main/GamesContainer';
import GameDetails from './components/play/game-details/main/GameDetailsContainer';
import PregameDetails from './components/play/pregame-details/main/PregameDetailsContainer';
import Terms from './components/text-pages/terms/TermsContainer';
import Faq from './components/text-pages/faq/FaqContainer.js';
import Result from './components/play/result/ResultContainer';
import Confirm from './components/play/confirm/ConfirmContainer';
import Unsubscribe from './components/unsubscribe/main/UnsubscribeContainer';
import Credits from './components/credits/CreditsContainer';

const Routes = () => {
	return (
		<Switch>
			<Route path="/" exact render={props => <CheckAuth><Auth {...props} /></CheckAuth>}></Route>
			<Route path="/signin" exact render={props => <CheckAuth><Auth {...props} /></CheckAuth>}></Route>
			<Route path="/signup" exact render={props => <CheckAuth><Auth {...props} /></CheckAuth>}></Route>
			<Route path="/forgot-password" exact render={props => <CheckAuth><Auth {...props} /></CheckAuth>}></Route>
			<Route path="/restricted" exact render={props => <CheckAuth restrictedOnly={true} restricted={true} {...props}><Auth {...props} /></CheckAuth>}></Route>
			<Route path="/about" exact render={props => <About {...props} />}></Route>
			<Route path="/leaderboard" exact render={props => <CheckAuth restricted={true}><Leaderboard {...props} /></CheckAuth>}></Route>
			<Route path="/profile" exact render={props => <CheckAuth restricted={true} initial={true}><Profile {...props} /></CheckAuth>}></Route>
			<Route path="/tos" exact render={props => <Terms {...props} />}></Route>
			<Route path="/faq" exact render={props => <Faq {...props} />}></Route>
			<Route path="/play-pregame" exact component={props => <CheckAuth restricted={true}><Games {...props} /></CheckAuth>}></Route>
			<Route path="/play-pregame/:id" exact render={props => <CheckAuth restricted={true}><PregameDetails {...props} /></CheckAuth>}></Route>
			<Route path="/play" exact render={props => <CheckAuth restricted={true}><Games {...props} /></CheckAuth>}></Route>
			<Route path="/play/:id" exact render={props => <CheckAuth restricted={true}><GameDetails {...props} /></CheckAuth>}></Route>
			<Route path="/result" exact render={props => <CheckAuth restricted={true}><Result {...props} /></CheckAuth>}></Route>
			<Route path="/confirm" exact render={props => <CheckAuth restricted={true}><Confirm {...props} /></CheckAuth>}></Route>
			<Route path="/unsubscribe" exact render={props => <CheckAuth restricted={true}><Unsubscribe {...props} /></CheckAuth>}></Route>
			<Route path="/unsubscribe" exact render={props => <CheckAuth restricted={true}><Unsubscribe {...props} /></CheckAuth>}></Route>
			<Route path="/credits" exact render={props => <Credits {...props} />}></Route>
		</Switch>
	);
};

export default Routes;
