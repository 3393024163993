import getText, { HtmlParser } from 'AppUtils/language';
import { getValue } from 'AppUtils/objects';
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import Button from '../../common/button/ButtonComponent';

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import './About.scss';

import iconCoin from '../../resources/img/icon-coin.png';
import iconCup from '../../resources/img/icon-cup.png';
import iconNewbie from '../../resources/img/icons-level/icon-newbie.png';
import iconPro from '../../resources/img/icons-level/icon-pro.png';
import iconSkilled from '../../resources/img/icons-level/icon-skilled.png';
import Logo from '../../resources/img/logo-256.png';

const AboutComponent = (props) => {
	const showOnboarding = getValue(props, 'user.eligibility.scheme') == 'credits' ? getValue(props, 'user.eligibility.eligible') : true;
	const buyCredits =  getValue(props, 'user.eligibility.scheme') == 'credits' && getValue(props, 'user.eligibility.credits') === 0;

	return (
		<div className="about-component vertical-center">
			<div className="about-slider">
				<Slider {...props.settings} ref={props.sliderRef}>
					<div className="item first-item">
						<div className="featured-img">
							<img src={Logo} alt="" className="img-responsive"/>
						</div>
						<p className="description">
							<HtmlParser html="homepage[text_slide_1]" />
						</p>
					</div>
					<div className="item second-item">
						<div className="featured-img">
							<span className="points-small">{getText('homepage[text_slide_2_points_small]')}</span>
							<span className="points-big">{getText('homepage[text_slide_2_points_big]')}</span>
							<img src={iconCup} alt="" className="img-responsive" />
						</div>
						<p className="description">
							<HtmlParser html="homepage[text_slide_2]" />
						</p>
					</div>
					<div className="item third-item">
						<div className="featured-img">
							<div className="skills-wrapper">
								<img src={iconPro} alt="" />
								<img src={iconNewbie} alt="" />
								<img src={iconSkilled} alt="" />
								<span>{getText('Pro')}</span>
								<span>{getText('Newbie')}</span>
								<span>{getText('Skilled')}</span>
							</div>
						</div>
						<p className="description">
							<HtmlParser html="homepage[text_slide_3]" />
						</p>
					</div>
					<div className="item fourth-item">
						<div className="featured-img">
							<img src={iconCoin} alt="" className="img-responsive" />
						</div>
						<p className="description">
							<HtmlParser html="homepage[text_slide_4]" />
						</p>
					</div>
				</Slider>
			</div>
			{showOnboarding && <Link to="/play" className="btn-slider" onClick={e => props.lang === 'ar' ? props.handleNextLtr(e) : props.handleNext(e)}>
				<Button
					fullWidth={true}
					boxShadow={true}
				>
					{props.lang === 'ar' ? (props.slideIndex === 0 ? getText("homepage[button_start]") : getText("homepage[button_next]")) : (props.slideIndex === 3 ? getText("homepage[button_start]") : getText("homepage[button_next]")) }
				</Button>
			</Link>}
			{buyCredits && <Link to="/credits" className="btn-slider btn-warning">
				<Button
					fullWidth={true}
					boxShadow={true}
				>
					<span>{getText('homepage[text_not_elligible_1]')}</span>
					<span>{getText('homepage[text_not_elligible_2]')}</span>
				</Button>
			</Link>}
		</div>
	);
};

export default AboutComponent;
