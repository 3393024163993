import React from "react";
import getText from "../../../../utils/language";

import "./PlayerStats.scss";

import iconCoin from "../../../../resources/img/icon-coin.png";
import iconGuru from "../../../../resources/img/icons-level/icon-guru.png";
import iconNewbie from "../../../../resources/img/icons-level/icon-newbie.png";
import iconPro from "../../../../resources/img/icons-level/icon-pro.png";
import iconSkilled from "../../../../resources/img/icons-level/icon-skilled.png";

const PlayerStatsComponent = (props) => {
	let iconLevel = "";

	switch (props.userInfo.level) {
		case 1:
			iconLevel = iconNewbie;
			break;
		case 2:
			iconLevel = iconSkilled;
			break;
		case 3:
			iconLevel = iconPro;
			break;
		case 4:
			iconLevel = iconGuru;
			break;
	}

	console.log('PROPS USER', props);
	return (
		<div className="player-stats-component">
			{/* <div className="stats-item">
				<img src={iconLevel} alt="" className="stats-avatar level-avatar"/>
				<div className="stats-info points-info">
					<span>{props.userInfo.points}</span>
					<span className="small">{getText('Points')}</span>
				</div>
			</div> */}
			<div className="stats-item">
				<img
					src={iconCoin}
					alt=""
					className="stats-avatar credits-avatar"
				/>
				<div className="stats-info credits-info">
					<span>
						{props.userInfo ? props.userInfo?.eligibility?.credits : 0}
					</span>
					<span className="small">{getText("Credits")}</span>
				</div>
			</div>
			{props.userInfo && (
				<div className="stats-item">
					<img
						src={iconLevel}
						alt=""
						className="stats-avatar level-avatar"
					/>
					<div className="stats-info points-info">
						<span>{props.userInfo.points}</span>
						<span className="small">
							{getText("header[text_points]")}
						</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default PlayerStatsComponent;
