import * as PLAY from './types';
import { PLAY_SET_GAMES } from "./types";

const defaultState = {
	section: 'play',
	msg: null,
	statusCode: null,
	games: null,
	pregames: null,
	pickPregame: null,
	playPregame: null,
	externalGames: null,
	start: null,
	finish: null,
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case PLAY.PLAY_SET_GAMES: {
			const { games, msg, statusCode } = action.payload;

			return {
				...state,
				games,
				msg,
				statusCode,
			};
		}
		case PLAY.PLAY_SET_PREGAMES: {
			const { pregames, msg, statusCode } = action.payload;

			return {
				...state,
				pregames,
				msg,
				statusCode,
			};
		}
		case PLAY.PLAY_SET_PICK_PREGAME: {
			const { pickPregame, msg, statusCode } = action.payload;

			return {
				...state,
				pickPregame,
				msg,
				statusCode,
			};
		}
		case PLAY.PLAY_SET_PLAY_PREGAME: {
			const { playPregame, msg, statusCode } = action.payload;

			return {
				...state,
				playPregame,
				msg,
				statusCode,
			};
		}
		case PLAY.PLAY_SET_EXTERNAL_GAMES: {
			const { externalGames, msg, statusCode } = action.payload;

			return {
				...state,
				externalGames,
				msg,
				statusCode,
			};
		}
		case PLAY.PLAY_SET_START_GAME: {
			const { start, msg, statusCode } = action.payload;

			return {
				...state,
				start,
				msg,
				statusCode,
			};
		}
		case PLAY.PLAY_SET_FINISH_GAME: {
			const { finish, msg, statusCode } = action.payload;

			return {
				...state,
				finish,
				msg,
				statusCode,
			};
		}
		default:
			return state;
	}
}
