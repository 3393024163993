import Observable from 'Observable';
import {
	put, fork, race, take, takeLatest, all, takeEvery, select, call
} from 'redux-saga/effects';
import { getValue } from 'AppUtils/objects';
import { apiGet, apiPost, apiPut } from "AppUtils/api";
import { getQueryVariable } from 'AppUtils/url';

import * as PLAY from './types';
import * as USER from '../../user/store/types';
import { authUuid, authApikey } from "../../auth/store/selectors";


function* onLoadGames(action) {
	//const uuid = yield select(authUuid);
	const platform = getValue(action, 'payload.platform', 'html5');

	const retry = 3;
	let games = '';
	let statusCode = '';
	let msg = '';

	const response = yield apiGet(`/games`, { platform })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				games = response;
			} else {
				msg = response.error;
			}
		});

	yield put({ type: PLAY.PLAY_SET_GAMES, payload: { games, msg, statusCode }});
}

function* onLoadPregames(action) {
	//const uuid = yield select(authUuid);
	const platform = getValue(action, 'payload.platform', 'html5');

	const retry = 3;
	let pregames = '';
	let statusCode = '';
	let msg = '';

	const response = yield apiGet(`/pregames`, { platform })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				pregames = response;
			} else {
				msg = response.error;
			}
		});

	yield put({ type: PLAY.PLAY_SET_PREGAMES, payload: { pregames, msg, statusCode }});
}

function* onLoadPickPregame(action) {
	const platform = getValue(action, 'payload.platform', 'html5');
	const uuid = yield select(authUuid);
	const id = getValue(action, 'payload.id');
	const extraLife = getValue(action, 'payload.extraLife');

	const retry = 3;
	let pickPregame = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost(`/pregames/pick`, { id, uuid, platform, extraLife })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				pickPregame = response;
			} else {
				msg = response.error;
			}
		});


	yield put({ type: PLAY.PLAY_SET_PICK_PREGAME, payload: { pickPregame, msg, statusCode }});
}

function* onLoadPlayPregame(action) {
	//const platform = getValue(action, 'payload.platform', 'html5');
	const uuid = yield select(authUuid);

	const retry = 3;
	let playPregame = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost(`/pregames/play`, { uuid })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise();

	if (response && !response.error) {
		playPregame = response;
		yield put({ type: USER.USER_LOAD_INFO });
	} else {
		msg = response.error;
	}

	yield put({ type: PLAY.PLAY_SET_PLAY_PREGAME, payload: { playPregame, msg, statusCode }});
}

function* onLoadExternalGames(action) {
	const apikey = yield select(authApikey);
	const platform = getValue(action, 'payload.platform', 'html5');

	const retry = 3;
	let externalGames = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiGet(`/external-games`, { platform }, { apikey })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				externalGames = response;
			} else {
				msg = response.error;
			}
		});

	yield put({ type: PLAY.PLAY_SET_EXTERNAL_GAMES, payload: { externalGames, msg, statusCode }});
}

function* onLoadStartGame(action) {
	const uuid = yield select(authUuid);
	const id = getValue(action, 'payload.id');

	const retry = 3;
	let start = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost(`/games/start`, { id, uuid })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				start = response;
			} else {
				msg = response.error;
			}
		});


	yield put({ type: PLAY.PLAY_SET_START_GAME, payload: { start, msg, statusCode }});
}

function* onLoadFinishGame(action) {
	const uuid = yield select(authUuid);
	const gamePlayId = getValue(action, 'payload.gamePlayId');
	const time = getValue(action, 'payload.time');
	const answers = getValue(action, 'payload.answers');

	const retry = 3;
	let finish = '';
	let msg = '';
	let statusCode = '';

	const response = yield apiPost(`/games/finish`, { gamePlayId, uuid, time, answers })
		.retryWhen(errors => errors.delay(1000).take(retry))
		.catch(e => Observable.of([]))
		.mergeMap(res => {
			const resp = res.json();
			statusCode = res.status;
			return resp;
		}).toPromise().then(function (response) {
			if (response && !response.error) {
				finish = response;
			} else {
				msg = response.error;
			}
		});

	yield put({ type: USER.USER_LOAD_INFO });
	yield put({ type: PLAY.PLAY_SET_FINISH_GAME, payload: { finish, msg, statusCode }});
}

function* playWatchInitialize() {
	yield takeEvery(PLAY.PLAY_LOAD_GAMES, onLoadGames);
	yield takeEvery(PLAY.PLAY_LOAD_PREGAMES, onLoadPregames);
	yield takeEvery(PLAY.PLAY_LOAD_PICK_PREGAME, onLoadPickPregame);
	yield takeEvery(PLAY.PLAY_LOAD_PLAY_PREGAME, onLoadPlayPregame);
	yield takeEvery(PLAY.PLAY_LOAD_EXTERNAL_GAMES, onLoadExternalGames);
	yield takeEvery(PLAY.PLAY_LOAD_START_GAME, onLoadStartGame);
	yield takeEvery(PLAY.PLAY_LOAD_FINISH_GAME, onLoadFinishGame);
}


export default function* sagas() {
	yield fork(playWatchInitialize);
}
