import React, {Component} from 'react';
import clsx from 'clsx';
import Timer from 'react-compound-timer'
import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';
import getText from 'AppUtils/language';

import './Quiz.scss';
import '../Timer.scss';

import iconTimer from '../../main/img/icon-timer.png';

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
	<Timer {...timerProps}>
		{timerRenderProps =>
			<WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
	</Timer>
);

class Quiz extends React.Component {
	constructor(props) {
		super(props)

		this.state = {current:0, dataSet:[], correct:0, incorrect:0, answerChoice: '', allChoices: {}, timeFinished: 0}
		this.handleClick = this.handleClick.bind(this)

	} // end constructor

	handleClick(choice) {
		this.props.timer.pause();
		if (choice == this.state.dataSet[this.state.current].correct) {
			let newDataSet = [...this.state.dataSet];
			newDataSet[this.state.current].touched = true;

			let allChoices = {...this.state.allChoices};
			const currentId = newDataSet[this.state.current].id;

			allChoices[newDataSet[this.state.current].id] = newDataSet[this.state.current].answers[choice].id;

			this.setState({
				correct: this.state.correct + 1,
				answerChoice: choice,
				dataSet: newDataSet,
				allChoices,
			})
		} else {
			let newDataSet = [...this.state.dataSet];
			newDataSet[this.state.current].touched = true;

			let allChoices = {...this.state.allChoices};
			const currentId = newDataSet[this.state.current].id;

			allChoices[newDataSet[this.state.current].id] = newDataSet[this.state.current].answers[choice] ? newDataSet[this.state.current].answers[choice].id : null;

			this.setState({
				incorrect: this.state.incorrect + 1,
				answerChoice: choice,
				dataSet: newDataSet,
				allChoices,
			})
		}

		if (this.state.current == this.state.dataSet.length - 1) {
			setTimeout(() => {
				this.handleNextQuestion(parseInt(this.props.timer.getTime()/1000));
			}, 1500);
		} else {
			setTimeout(() => {
				this.handleNextQuestion(parseInt(this.props.timer.getTime()/1000));
			}, 1500);

		}
	}

	handleNextQuestion = (timeRemaining) => {
		let isLast = this.state.current == this.state.dataSet.length - 1;

		this.setState({
			current: !isLast ? this.state.current + 1 : this.state.current,
			timeFinished: timeRemaining ? this.state.timeFinished + (this.state.maxSecondsPerQuestion - timeRemaining) : this.state.timeFinished + this.state.maxSecondsPerQuestion,
		}, () => {
			this.props.timer.reset();
			if(isLast) {
				this.props.timer.pause();
				this.props.uiRedirect({
					pathname: '/result',
					state: {
						gamePlayId: parseInt(this.props.playStartGame.id),
						time: this.state.timeFinished,
						answers: this.state.allChoices,
					}
				})
			} else {
				this.props.timer.start();
			}
		})
	}

	componentDidMount() {
		let questions = [...this.props.playGetGame.data.questions];
		let correct = '';
		let allChoices = {};

		for (let i = 0; i < questions.length; i++) {
			questions[i].touched = false;
			allChoices[questions[i].id] = null;

			for(let j = 0; j < questions[i].answers.length; j++) {
				if(questions[i].answers[j].correct) {
					correct = j;
				}
			}

			questions[i].correct = correct;
		}

		this.setState({
			dataSet: questions,
			allChoices,
			maxSecondsPerQuestion: this.props.playGetGame.data.maxSecondsPerQuestion,
		})

		this.props.timer.setCheckpoints([
			{
				time: 0,
				callback: () => {
					// this.handleNextQuestion();
					this.handleClick();
				},
			},
		]);
		this.props.timer.setTime(this.props.playGetGame.data.maxSecondsPerQuestion * 1000 + 500);
		this.props.timer.start();
	}

	render() {
		if(this.state.dataSet.length) {
			return(
				<div className="quiz">
					{/*<ScoreArea correct={this.state.correct} incorrect={this.state.incorrect} />*/}
					<div className="title">
						<h2>{this.props.playGetGame.name[this.props.lang]}</h2>
						<p>{this.props.playGetGame.subtitle[this.props.lang]}</p>
					</div>
					<div className="game-timer">
						<img src={iconTimer} alt=""/> <div className="time-left"><Timer.Minutes formatValue={value => ("0" + value).slice(-2)} />:<Timer.Seconds formatValue={value => ("0" + value).slice(-2)} /></div>
					</div>
					<QuizArea handleClick={this.handleClick} dataSet={this.state.dataSet[this.state.current]} answerChoice={this.state.answerChoice} lang={this.props.lang} />
					<p className="total-questions">
						{this.state.current + 1}/{this.state.dataSet.length}
					</p>
				</div>
			)
		} else {
			return null;
		}
	}
}

const TimerHOC = withTimer({
	direction: 'backward',
	initialTime: 15000,
	startImmediately: false,
})(Quiz);

<TimerHOC />

function Question(props) {
	return (
		<h3 className="question">{props.dataSet.question[props.lang]}</h3>
	)
}

function Answer(props) {
	return(
		<CSSTransition
			key={props.id}
			timeout={200 + (200 * (props.index + 1)/2)}
			classNames="answer-transition"
			in={props.dataSet ? true : false}
			id={props.i}
		>
			<div
				className={clsx({
					answer: true,
					selected: props.choice === props.answerChoice,
					correct: props.choice === props.answerChoice && props.dataSet.touched && props.choice === props.dataSet.correct,
					incorrect: props.choice === props.answerChoice && props.dataSet.touched && props.choice !== props.dataSet.correct
				})}
				style={{transitionDelay: `${200 * (props.index + 1)/2}ms`}}
			>
				<button onClick={(e) => {
					!props.dataSet.touched ?
						props.handleClick(props.choice)
						:
						e.preventDefault()
				}}>{props.answer[props.lang]}</button>
			</div>
		</CSSTransition>
	)
}

function AnswerList(props) {
	var answers = [];
	for (let i = 0; i < props.dataSet.answers.length; i++) {
		answers.push(
			<Answer index={i} id={props.dataSet.answers[i].id} choice={i} handleClick={props.handleClick} answer={props.dataSet.answers[i].answer} dataSet={props.dataSet} answerChoice={props.answerChoice} lang={props.lang} />
		)
	}
	return(
		<TransitionGroup className="answers-list-transitions">
			<div className="answers-list">
				{answers}
			</div>
		</TransitionGroup>
	)
}

function QuizArea(props) {
	return(
		<div className="quiz-area">
			<Question dataSet={props.dataSet} lang={props.lang} />
			<AnswerList dataSet={props.dataSet} handleClick={props.handleClick} answerChoice={props.answerChoice} lang={props.lang} />
		</div>
	)
}

function TotalCorrect(props) {
	var style = {
		display: "inline-block",
		padding: "1em",
		background: "#eee",
		margin: "0 1em 0 0"
	}
	return(
		<h2 style={style}>Correct: {props.correct}</h2>
	)
}

function TotalIncorrect(props) {
	var style = {
		display: "inline-block",
		padding: "1em",
		background: "#eee",
		margin: "0 0 0 1em"
	}
	return(
		<h2 style={style}>Incorrect: {props.incorrect}</h2>
	)
}

function ScoreArea(props) {
	var style = {
		width: "100%",
		display: "flex",
		textAlign: "left",
		padding: "2em"
	}
	return(
		<div style={style} >
			<TotalCorrect correct={props.correct} />
			<TotalIncorrect incorrect={props.incorrect} />
		</div>
	)
}

export default TimerHOC;
