import React, {Component} from 'react';
import {connect} from 'react-redux';

import Knock from './KnockComponent';

import {playGetPlayPregame} from '../../../store/selectors';
import {uiRedirect} from '../../../../../store/actions';
import {playLoadPlayPregame} from '../../../store/actions';

import knock from '../../../../../resources/sounds/knock.mp3';

const stateToProps = state => ({
	playGetPlayPregame: playGetPlayPregame(state)
});

const actionsToProps = dispatch => ({
	uiRedirect: (url) => dispatch(uiRedirect(url)),
	playLoadPlayPregame: (payload) => dispatch(playLoadPlayPregame(payload)),
});

@connect(stateToProps, actionsToProps)
class KnockContainer extends Component {

	audioKnock = new Audio(knock);

	constructor(props) {
		super(props);
		this.state = {
			playGetPregame: props.playGetPregame,
			prizeId: props.prizeId,
			isFlipped: null
		};

		if(!props.playGetPregame) {
			props.uiRedirect({
				pathname: '/'
			})
		}
	}

	getPrizeIndexWithId = () => {
		let prize = this.state.playGetPregame.prizes.filter((prize, index) => prize.id === this.state.prizeId);
		return this.state.playGetPregame.prizes.indexOf(prize[0]);
	}

	handleKnock = (index) => {
		if(this.state.isFlipped === null) {
			this.audioKnock.play();
			this.setState({
				isFlipped: index
			}, () => {
				setTimeout(() => {
					this.props.playLoadPlayPregame({id: this.state.playGetPregame.id});
				}, 1000)
			})
		}
	}

	componentDidMount() {
		this.audioKnock.pause();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let prize = this.state.playGetPregame.prizes.filter((prize, index) => prize.id === this.state.prizeId);

		if(prevProps.playGetPlayPregame !== this.props.playGetPlayPregame && this.props.playGetPlayPregame) {
			this.props.uiRedirect({
				pathname: '/confirm',
				state: {
					prize: prize[0]
				}
			})
		}
	}

	render() {
		let prize = this.state.playGetPregame.prizes.filter((prize, index) => prize.id === this.state.prizeId)[0];

		return (
			<Knock
				lang={this.props.lang}
				playGetPregame={this.state.playGetPregame}
				prize={prize}
				handleKnock={this.handleKnock}
				isFlipped={this.state.isFlipped}
			/>
		);
	}
}

export default KnockContainer;
