import React, {useState} from 'react';
import { useGesture } from 'react-use-gesture';
import { useSprings, animated } from 'react-spring';

import './Puzzle.scss';
import iconTimer from '../../main/img/icon-timer.png';
import Timer from 'react-compound-timer';

const PuzzleGameComponent = (props) => {
	const [springs, setSprings] = useSprings(props.answers.length, index => ({ xy: [0, 0] }));
	const [finished, setFinished] = useState(false);
	const [initialCoordsSelected, setInitialCoordsSelected] = useState({x: 0, y: 0});

	const calculateIntersect = (rect1, rect2) => {
		let x_overlap = Math.max(0, Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left));
		let y_overlap = Math.max(0, Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top));
		let overlapArea = x_overlap * y_overlap;

		return overlapArea;
	}

	const handleDragStart = (index) => {
		setInitialCoordsSelected({x: props.answersListRef[index].getBoundingClientRect().x, y: props.answersListRef[index].getBoundingClientRect().y});
	}

	const handleDrag = (index, delta, down) => {
		if(!finished) {
			setSprings(springIndex => {
				if(springIndex === index) {
					if(down) {
						return {
							xy:  delta, immediate: true
						}
					}
				}
			})
		}
	}

	const handleDragEnd = (index, delta, down) => {
		const originalWithoutBound = props.wrapperCanvas.current.getBoundingClientRect();
		let originalWithBounding = {
			left: originalWithoutBound.left,
			right: originalWithoutBound.right,
			top: originalWithoutBound.top,
			bottom: originalWithoutBound.bottom,
		}

		const answerWithoutBound = props.answersListRef[index].getBoundingClientRect();
		let answerWithBounding = {
			left: answerWithoutBound.left,
			right: answerWithoutBound.right,
			top: answerWithoutBound.top,
			bottom: answerWithoutBound.bottom,
		}

		const intersectPercentage = calculateIntersect(originalWithBounding, answerWithBounding);

		setSprings(springIndex => {
			if(springIndex === index) {
				if(intersectPercentage === 0) {
					return {
						xy: [0, 0]
					}
				} else {
					setFinished(true)
					props.timer.pause();
					props.setChoice(props.answers[index])
					setTimeout(() => {
						props.finishGame();
					}, 1000)
					if(props.answers[index].pos === props.missingPiece.pos) {
						const missingPieceBounding = props.pieceListRef[props.answers[index].pos].getBoundingClientRect();
						const selectedPieceBounding = initialCoordsSelected;

						const boundingDifference = {
							x: selectedPieceBounding.x - missingPieceBounding.x,
							y: selectedPieceBounding.y - missingPieceBounding.y,
						}

						return {
							xy: [-boundingDifference.x, -boundingDifference.y]
						}
					}
				}
			}
		})
	}

	const bindDrag = useGesture({
		onDragStart: ({ args: [originalIndex], down, delta, velocity }) => {
			console.log('drag')
			handleDragStart(originalIndex);
		},
		onDrag: ({ args: [originalIndex], down, delta, velocity }) => {
			console.log('dragging')
			console.log(originalIndex, 'originalIndex')
			handleDrag(originalIndex, delta, down);
		},
		onDragEnd: ({ args: [originalIndex], down, delta, velocity }) => {
			handleDragEnd(originalIndex, delta, down);
		}
	})

	const renderAnswers = () => {
		if(props.answers.length) {
			return springs.map(({xy}, i) => {
				return (
					<animated.li
						key={i}
						className={`answer-puzzle-piece answer-puzzle-piece-${i+1}`}
						style={{ transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`), width: props.piecesSize[props.answers[i].pos].width, height: props.piecesSize[props.answers[i].pos].height }}
						{...bindDrag(i)}
						ref={answersListRef => props.answersListRef[i] = answersListRef}
					>
						{/*<svg className={`piece piece-${i+1}`} viewBox="0 0 95 95">*/}
						{/*	<defs>*/}
						{/*		<clipPath id={`clipPiece${i}`}>*/}
						{/*			<foreignObject width="50" height="50">*/}
						{/*				/!*<canvas ref={answerPiecesCanvas => props.answerPiecesCanvas[i] = answerPiecesCanvas}></canvas>*!/*/}
						{/*				/!*<div className="image" style={{*!/*/}
						{/*				/!*	backgroundImage: `url(http://placekitten.com/g/300/300)`*!/*/}
						{/*				/!*}}></div>*!/*/}
						{/*			</foreignObject>*/}
						{/*		</clipPath>*/}
						{/*	</defs>*/}

						{/*	<image width="100%" height="100%" preserveAspectRatio="xMidYMid slice" xlinkHref="http://placekitten.com/g/300/300" clipPath={`url(#clipPiece${i})`}/>*/}
						{/*	/!*<foreignObject clipPath={`url(#clipPiece${i})`} width="100%" height="100%">*!/*/}
						{/*	/!*	/!*<canvas ref={answerPiecesCanvas => props.answerPiecesCanvas[i] = answerPiecesCanvas}></canvas>*!/*!/*/}
						{/*	/!*</foreignObject>*!/*/}
						{/*</svg>*/}

						<div className="image-piece" style={{
							width: props.piecesSize[props.answers[i].pos].width,
							height: props.piecesSize[props.answers[i].pos].height,
							backgroundImage: `url(${props.playGetGame.data.image.image})`,
							backgroundSize: '223px auto',
							backgroundPosition: props.piecesBackgroundPos[props.answers[i].pos] ? `${props.piecesBackgroundPos[props.answers[i].pos].left}px ${props.piecesBackgroundPos[props.answers[i].pos].top}px` : '0 0',
							clipPath: `url(#clip-answer-${i})`
						}}></div>

						<svg
							className={`piece piece-${i+1}`}
							viewBox={props.svgModels[props.answers[i].pos].viewBox}
							height="0"
							width="0"
							version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsLink="http://www.w3.org/1999/xlink" x="0px" y="0px"
						>
							<defs>
								<clipPath id={`clip-answer-${i}`}>
									<path d={props.svgModels[props.answers[i].pos].pathMain} />
								</clipPath>
							</defs>
						</svg>
						<svg
							className={`piece-stroke`}
							viewBox={props.svgModels[props.answers[i].pos].viewBox}
							height={props.piecesSize[props.answers[i].pos].height}
							width={props.piecesSize[props.answers[i].pos].width}
							fill="#fff"
							version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsLink="http://www.w3.org/1999/xlink" x="0px" y="0px"
						>
							<path d={props.svgModels[props.answers[i].pos].pathStroke} />
						</svg>
					</animated.li>
				)
			})
		}
	}

	const renderPieces = () => {
		return props.svgModels.map((piece, i) => {
			return (
				<li
					className={`puzzle-piece puzzle-piece-${i+1}`}
					style={{
						width: props.piecesSize[i].width,
						height: props.piecesSize[i].height
					}}
					ref={pieceListRef => props.pieceListRef[i] = pieceListRef}
				>
					{/*<svg className={`piece piece-${i+1}`} viewBox={props.svgModels[i].viewBox} width={props.piecesSize[i].width}>*/}
					{/*	<defs>*/}
					{/*		<clipPath id={`clipPiece${i}`}>*/}
					{/*			{props.svgModels[i].pathMain}*/}
					{/*		</clipPath>*/}
					{/*	</defs>*/}

					{/*	{props.svgModels[i].pathStroke}*/}
					{/*	<foreignObject clip-path={`url(#clipPiece${i})`} width="100%" height="100%">*/}
					{/*		/!*<canvas*!/*/}
					{/*		/!*	ref={pieceListRef => props.pieceListRef[i] = pieceListRef}*!/*/}
					{/*		/!*></canvas>*!/*/}
					{/*		<div className="image"></div>*/}
					{/*	</foreignObject>*/}
					{/*</svg>*/}

					{ props.missingPiece.pos !== i ?
						<div className="image-piece" style={{
							width: props.piecesSize[i].width,
							height: props.piecesSize[i].height,
							backgroundImage: `url(${props.playGetGame.data.image.image})`,
							backgroundSize: '223px auto',
							backgroundPosition: props.piecesBackgroundPos[i] ? `${props.piecesBackgroundPos[i].left}px ${props.piecesBackgroundPos[i].top}px` : '0 0',
							clipPath: `url(#clip-piece-${i})`
						}}></div>
						: null
					}

					<svg
						className={`piece piece-${i+1}`}
						viewBox={props.svgModels[i].viewBox}
						height="0"
						width="0"
						version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsLink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					>
						<defs>
							<clipPath id={`clip-piece-${i}`}>
								<path d={props.svgModels[i].pathMain} />
							</clipPath>
						</defs>
					</svg>

					<svg
						className={`piece-stroke`}
						viewBox={props.svgModels[i].viewBox}
						height={props.piecesSize[i].height}
						width={props.piecesSize[i].width}
						version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsLink="http://www.w3.org/1999/xlink" x="0px" y="0px"
						fill="#fff"
					>
						<path d={props.svgModels[i].pathStroke} />
					</svg>
				</li>
			)
		})
	}


	return (
		<div className="puzzle-game">
			<div className="title">
				<h2>{props.playGetGame.name[props.lang]}</h2>
				<p>{props.playGetGame.subtitle[props.lang]}</p>
			</div>
			<div className="game-timer">
				<img src={iconTimer} alt=""/> <div className="time-left"><Timer.Minutes formatValue={value => ("0" + value).slice(-2)} />:<Timer.Seconds formatValue={value => ("0" + value).slice(-2)} /></div>
			</div>

			<ul className="wrapper-canvas" ref={props.wrapperCanvas}>
				{renderPieces()}

				{/*<li className={`puzzle-piece puzzle-piece-1`}>*/}

				{/*	<svg viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg" width="300" height="300">*/}
				{/*		<defs>*/}
				{/*			<image xlinkHref="http://placekitten.com/g/300/300" id="smiley" width="300" height="300" />*/}
				{/*		</defs>*/}

				{/*		<svg preserveAspectRatio="xMinYMid slice" x="0" y="0" width="95"  height="95">*/}
				{/*			<use href="#smiley" />*/}
				{/*		</svg>*/}
				{/*		<svg x="0" y="0" width="95"  height="95" viewBox={props.svgModels[0].viewBox}>*/}
				{/*			<path d={props.svgModels[0].pathMain} />*/}
				{/*		</svg>*/}

				{/*		<svg preserveAspectRatio="xMidYMid slice" x={props.piecesSize[0].width} y="0" width="95"  height="95">*/}
				{/*			<use href="#smiley" />*/}
				{/*		</svg>*/}
				{/*		<svg x={props.piecesSize[0].width} y="0" width="95"  height="95" viewBox={props.svgModels[0].viewBox}>*/}
				{/*			<path d={props.svgModels[0].pathMain} />*/}
				{/*		</svg>*/}

				{/*		<svg preserveAspectRatio="xMaxYMid slice" x={props.piecesSize[0].width*2} y="0" width="95"  height="95">*/}
				{/*			<use href="#smiley" />*/}
				{/*		</svg>*/}
				{/*		<svg x={props.piecesSize[0].width*2} y="0" width="95"  height="95" viewBox={props.svgModels[0].viewBox}>*/}
				{/*			<path d={props.svgModels[0].pathMain} />*/}
				{/*		</svg>*/}
				{/*	</svg>*/}
				{/*</li>*/}

		{/*		<li className="puzzle-piece puzzle-piece-1">*/}
		{/*			<svg className="piece piece-1" viewBox="0 0 362.8 291.4" height="76" width="95">*/}
		{/*				<defs>*/}
		{/*					<clipPath id="clipPiece1">*/}
		{/*						<path d="M195.3,289.9c-13.9,0-21.9-2.6-25.3-8.1c-3.6-5.9-2-15.8,4.8-29.4c8.3-16.5,5.3-27.5,1.4-33.8*/}
		{/*c-6.1-9.8-19.3-16-34.5-16c-15.2,0-28.4,6.1-34.5,16c-3.9,6.3-6.8,17.3,1.4,33.8c6.8,13.7,8.5,23.6,4.8,29.4*/}
		{/*c-3.4,5.5-11.5,8.1-25.3,8.1c-11.6,0-25.7-1.8-40.7-3.7c-15.5-2-31.6-4.1-46-4.2V1.5H282c-0.1,14.2-2.2,30.2-4.2,45.6*/}
		{/*c-3.7,28.4-7.1,55.2,2.5,66.1c3,3.4,7.2,5.2,12.4,5.2c0,0,0,0,0,0c6.1,0,13.7-2.3,23.2-7.1c6.6-3.3,12.8-5,18.4-5*/}
		{/*c18.6,0,27.1,18.3,27.1,35.4c0,17-8.5,35.4-27.1,35.4c-5.6,0-11.8-1.7-18.4-5c-9.5-4.7-17.1-7.1-23.2-7.1c-5.2,0-9.4,1.7-12.4,5.2*/}
		{/*c-9.6,10.9-6.1,37.7-2.5,66.1c2,15.4,4,31.4,4.2,45.6c-14.4,0.1-30.5,2.2-46,4.2C221,288.1,206.9,289.9,195.3,289.9z"/>*/}
		{/*					</clipPath>*/}
		{/*				</defs>*/}

		{/*				<path d="M280.4,3c-0.3,13.8-2.2,29.1-4.1,43.9c-3.9,30-7.2,55.9,2.8,67.3c3.3,3.8,7.9,5.7,13.5,5.7c6.4,0,14.2-2.4,23.9-7.2*/}
		{/*c6.4-3.2,12.4-4.8,17.7-4.8c17.6,0,25.6,17.6,25.6,33.9c0,16.3-8,33.9-25.6,33.9c-5.3,0-11.3-1.6-17.7-4.8*/}
		{/*c-9.7-4.9-17.5-7.2-23.9-7.2c-5.7,0-10.2,1.9-13.5,5.7c-10,11.4-6.7,37.3-2.8,67.3c1.9,14.9,3.9,30.2,4.1,44*/}
		{/*c-14.1,0.3-29.6,2.3-44.7,4.2c-14.9,1.9-28.9,3.7-40.5,3.7c-13.1,0-21-2.4-24-7.4c-3.3-5.4-1.7-14.8,4.9-28*/}
		{/*c8.6-17.1,5.5-28.6,1.4-35.3c-6.4-10.3-20.1-16.7-35.8-16.7c-15.7,0-29.4,6.4-35.8,16.7c-4.1,6.6-7.2,18.2,1.3,35.3*/}
		{/*c6.6,13.2,8.2,22.6,4.9,28c-3.1,5-10.9,7.4-24,7.4c-11.5,0-25.6-1.8-40.5-3.7c-15.1-1.9-30.6-3.9-44.7-4.2V3H280.4 M283.5,0H0*/}
		{/*v283.5c28.3,0,63.8,7.9,88.2,7.9c24.4,0,37.7-7.9,21.8-39.7c-15.9-31.8,7.9-47.6,31.8-47.6c23.8,0,47.6,15.9,31.7,47.6*/}
		{/*c-15.9,31.7-2.6,39.7,21.8,39.7s59.8-7.9,88.2-7.9c0-48.2-22.9-116.9,9.2-116.9c5.7,0,13,2.1,22.6,6.9c7.2,3.6,13.5,5.1,19.1,5.1*/}
		{/*c38.1,0,38.1-73.8,0-73.8c-5.5,0-11.9,1.6-19,5.1c-9.5,4.8-16.9,6.9-22.6,6.9C260.5,116.9,283.5,48.2,283.5,0L283.5,0z"/>*/}
		{/*				<foreignObject clip-path="url(#clipPiece1)" width="100%" height="100%">*/}
		{/*					<div className="image"*/}
		{/*						 style={{background: "url(http://placekitten.com/g/300/300) center / cover"}}>*/}
		{/*					</div>*/}
		{/*				</foreignObject>*/}
		{/*			</svg>*/}
		{/*		</li>*/}
		{/*		<li className="puzzle-piece puzzle-piece-2">*/}
		{/*			<svg className="piece piece-1" viewBox="0 0 370.8 291.4" height="76" width="95">*/}
		{/*				<defs>*/}
		{/*					<clipPath id="clipPiece2">*/}
		{/*						<path className="st0" d="M203.2,289.9c-13.9,0-21.9-2.6-25.3-8.1c-3.6-5.9-2-15.8,4.8-29.4c8.3-16.5,5.3-27.5,1.4-33.8*/}
		{/*c-6.1-9.8-19.3-16-34.5-16s-28.4,6.1-34.5,16c-3.9,6.3-6.8,17.3,1.4,33.8c6.8,13.7,8.5,23.6,4.8,29.4c-3.4,5.5-11.5,8.1-25.3,8.1*/}
		{/*c-11.6,0-25.7-1.8-40.7-3.7c-15.5-2-31.6-4.1-46-4.2c-0.1-14.4-2.2-30.5-4.2-46c-3.6-27.7-6.9-53.9,1.7-63.7*/}
		{/*c2.4-2.8,5.8-4.1,10.2-4.1c5.6,0,12.9,2.3,21.9,6.7c7,3.5,13.7,5.3,19.7,5.3c20.7,0,30.1-19.9,30.1-38.4c0-18.5-9.4-38.4-30.1-38.4*/}
		{/*c-6.1,0-12.7,1.8-19.7,5.3c-8.9,4.5-16.3,6.7-21.9,6.7c0,0,0,0,0,0c-4.4,0-7.7-1.4-10.2-4.1c-8.7-9.8-5.3-36-1.7-63.7*/}
		{/*c2-15.5,4.1-31.6,4.2-46h280.5c-0.1,14.2-2.2,30.2-4.2,45.6c-3.7,28.4-7.1,55.2,2.5,66.1c3,3.4,7.2,5.2,12.4,5.2h0*/}
		{/*c6.1,0,13.7-2.3,23.2-7.1c6.6-3.3,12.8-5,18.4-5c18.6,0,27.1,18.3,27.1,35.4c0,17-8.5,35.4-27.1,35.4c-5.6,0-11.8-1.7-18.4-5*/}
		{/*c-9.5-4.7-17.1-7.1-23.2-7.1c-5.2,0-9.4,1.7-12.4,5.2c-9.6,10.9-6.1,37.7-2.5,66.1c2,15.4,4,31.4,4.2,45.6*/}
		{/*c-14.4,0.1-30.5,2.2-46,4.2C229,288.1,214.9,289.9,203.2,289.9z"/>*/}
		{/*					</clipPath>*/}
		{/*				</defs>*/}

		{/*				<path d="M288.4,3c-0.3,13.8-2.2,29.1-4.1,43.9c-3.9,30-7.2,55.9,2.8,67.3c3.3,3.8,7.9,5.7,13.5,5.7c6.4,0,14.2-2.4,23.9-7.2*/}
		{/*c6.4-3.2,12.4-4.8,17.7-4.8c17.6,0,25.6,17.6,25.6,33.9c0,16.3-8,33.9-25.6,33.9c-5.3,0-11.3-1.6-17.7-4.8*/}
		{/*c-9.7-4.9-17.5-7.2-23.9-7.2c-5.7,0-10.2,1.9-13.5,5.7c-10,11.4-6.7,37.3-2.8,67.3c1.9,14.9,3.9,30.2,4.1,44*/}
		{/*c-14.1,0.3-29.6,2.3-44.7,4.2c-14.9,1.9-28.9,3.7-40.5,3.7c-13.1,0-21-2.4-24-7.4c-3.3-5.4-1.7-14.8,4.9-28*/}
		{/*c8.6-17.1,5.5-28.6,1.4-35.3c-6.4-10.3-20.1-16.7-35.8-16.7s-29.4,6.4-35.8,16.7c-4.1,6.6-7.2,18.2,1.4,35.3*/}
		{/*c6.6,13.2,8.2,22.6,4.9,28c-3.1,5-10.9,7.4-24,7.4c-11.5,0-25.6-1.8-40.5-3.7c-15.1-1.9-30.6-3.9-44.7-4.2*/}
		{/*c-0.3-14.1-2.3-29.7-4.2-44.7c-3.4-26.2-6.8-53.2,1.4-62.6c2.1-2.4,5.1-3.6,9-3.6c5.4,0,12.5,2.2,21.2,6.6*/}
		{/*c7.2,3.6,14.1,5.5,20.4,5.5c21.7,0,31.6-20.7,31.6-39.9c0-19.2-9.9-39.9-31.6-39.9c-6.3,0-13.1,1.8-20.4,5.5*/}
		{/*c-8.7,4.4-15.9,6.6-21.2,6.6c-3.9,0-6.9-1.2-9-3.6c-8.2-9.3-4.7-36.4-1.4-62.6c1.9-15,3.9-30.6,4.2-44.7H288.4 M291.4,0H7.9*/}
		{/*c0,48.2-22.9,116.9,9.2,116.9c5.7,0,13-2.1,22.6-6.9c7.2-3.6,13.5-5.1,19-5.1c38.1,0,38.1,73.8,0,73.8c-5.5,0-11.9-1.6-19.1-5.1*/}
		{/*c-9.5-4.8-16.9-6.9-22.6-6.9c-32.1,0-9.2,68.7-9.2,116.9c28.4,0,63.8,7.9,88.2,7.9c24.4,0,37.7-7.9,21.8-39.7*/}
		{/*c-15.9-31.8,7.9-47.6,31.7-47.6c23.8,0,47.6,15.9,31.7,47.6c-15.9,31.7-2.6,39.7,21.8,39.7s59.8-7.9,88.2-7.9*/}
		{/*c0-48.2-23-116.9,9.2-116.9c5.7,0,13,2.1,22.6,6.9c7.2,3.6,13.5,5.1,19,5.1c38.1,0,38.1-73.8,0-73.8c-5.5,0-11.9,1.6-19,5.1*/}
		{/*c-9.5,4.8-16.9,6.9-22.6,6.9C268.5,116.9,291.4,48.2,291.4,0L291.4,0z"/>*/}
		{/*				<foreignObject clip-path="url(#clipPiece2)" width="100%" height="100%">*/}
		{/*					<div className="image"*/}
		{/*						 style={{background: "url(http://placekitten.com/g/300/300) center / cover"}}>*/}
		{/*					</div>*/}
		{/*				</foreignObject>*/}
		{/*			</svg>*/}
		{/*		</li>*/}
		{/*		<li className="puzzle-piece puzzle-piece-3">*/}
		{/*			<svg className="piece piece-1" viewBox="0 0 291.4 291.4" height="76" width="95">*/}
		{/*				<defs>*/}
		{/*					<clipPath id="clipPiece3">*/}
		{/*						<path className="st0" d="M203.2,289.9c-13.9,0-21.9-2.6-25.3-8.1c-3.6-5.9-2-15.8,4.8-29.4c8.3-16.5,5.3-27.5,1.4-33.8*/}
		{/*c-6.1-9.8-19.3-16-34.5-16c-15.2,0-28.4,6.1-34.5,16c-3.9,6.3-6.8,17.3,1.4,33.8c6.8,13.7,8.5,23.6,4.8,29.4*/}
		{/*c-3.4,5.5-11.5,8.1-25.3,8.1c-11.6,0-25.7-1.8-40.7-3.7c-15.5-2-31.6-4.1-46-4.2c-0.1-14.4-2.2-30.5-4.2-46*/}
		{/*c-3.6-27.7-6.9-53.9,1.7-63.7c2.4-2.8,5.8-4.1,10.2-4.1c5.6,0,13,2.3,21.9,6.7c7,3.5,13.7,5.3,19.7,5.3c20.7,0,30.1-19.9,30.1-38.4*/}
		{/*c0-18.5-9.4-38.4-30.1-38.4c-6.1,0-12.7,1.8-19.7,5.3c-8.9,4.5-16.3,6.7-21.9,6.7c0,0,0,0,0,0c-4.4,0-7.7-1.4-10.2-4.1*/}
		{/*c-8.7-9.8-5.3-36-1.7-63.7c2-15.5,4.1-31.6,4.2-46h280.5V282c-14.4,0.1-30.5,2.2-46,4.2C229,288.1,214.9,289.9,203.2,289.9z"/>*/}
		{/*					</clipPath>*/}
		{/*				</defs>*/}

		{/*				<path d="M288.4,3v277.5c-14.1,0.3-29.6,2.3-44.7,4.2c-14.9,1.9-28.9,3.7-40.5,3.7c-13.1,0-21-2.4-24-7.4c-3.3-5.4-1.7-14.8,4.9-28*/}
		{/*c8.6-17.1,5.5-28.6,1.4-35.3c-6.4-10.3-20.1-16.7-35.8-16.7c-15.7,0-29.4,6.4-35.8,16.7c-4.1,6.6-7.2,18.2,1.3,35.3*/}
		{/*c6.6,13.2,8.2,22.6,4.9,28c-3.1,5-10.9,7.4-24,7.4c-11.5,0-25.6-1.8-40.5-3.7c-15.1-1.9-30.6-3.9-44.7-4.2*/}
		{/*c-0.3-14.1-2.3-29.7-4.2-44.7c-3.4-26.2-6.8-53.2,1.4-62.6c2.1-2.4,5.1-3.6,9-3.6c5.4,0,12.5,2.2,21.2,6.6*/}
		{/*c7.2,3.6,14.1,5.5,20.4,5.5c21.7,0,31.6-20.7,31.6-39.9c0-19.2-9.9-39.9-31.6-39.9c-6.3,0-13.1,1.8-20.4,5.5*/}
		{/*c-8.7,4.4-15.9,6.6-21.2,6.6c-3.9,0-6.9-1.2-9-3.6c-8.2-9.3-4.7-36.4-1.4-62.5c1.9-15,3.9-30.6,4.2-44.7H288.4 M291.4,0H7.9*/}
		{/*c0,48.2-23,116.9,9.2,116.9c5.7,0,13-2.1,22.6-6.9c7.2-3.6,13.5-5.1,19-5.1c38.1,0,38.1,73.8,0,73.8c-5.5,0-11.9-1.6-19-5.1*/}
		{/*c-9.5-4.8-16.9-6.9-22.6-6.9c-32.1,0-9.2,68.7-9.2,116.9c28.3,0,63.8,7.9,88.2,7.9c24.4,0,37.7-7.9,21.8-39.7*/}
		{/*c-15.9-31.8,7.9-47.6,31.8-47.6s47.6,15.9,31.7,47.6c-15.9,31.7-2.6,39.7,21.8,39.7c24.4,0,59.8-7.9,88.2-7.9V0L291.4,0z"/>*/}
		{/*				<foreignObject clip-path="url(#clipPiece3)" width="100%" height="100%">*/}
		{/*					<div className="image"*/}
		{/*						 style={{background: "url(http://placekitten.com/g/300/300) center / cover"}}>*/}
		{/*					</div>*/}
		{/*				</foreignObject>*/}
		{/*			</svg>*/}
		{/*		</li>*/}
		{/*		<li className="puzzle-piece puzzle-piece-4"></li>*/}
		{/*		<li className="puzzle-piece puzzle-piece-5"></li>*/}
		{/*		<li className="puzzle-piece puzzle-piece-6"></li>*/}
		{/*		<li className="puzzle-piece puzzle-piece-7"></li>*/}
		{/*		<li className="puzzle-piece puzzle-piece-8"></li>*/}
		{/*		<li className="puzzle-piece puzzle-piece-9"></li>*/}
			</ul>

			<ul className="answers-list">
				{renderAnswers()}
			</ul>
		</div>
	);
};

export default PuzzleGameComponent;
